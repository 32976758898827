<template>
    <template v-if="dataGridControl">
        <div v-if="!hideHeader" class="hstack min-h-unset mt-2">
            <h6 class="mb-1 me-2">{{ $t('Summary') }}</h6>
            <small class="text-truncate">{{$t('Select aggregates for columns to be shown at the footer')}}</small>
        </div>
        <small v-else class="text-truncate mt-2">{{$t('Select aggregates for columns to be shown at the footer')}}</small>
        <ODataGrid  :data="columns" hideMenu noFooter hideActionColumn hideSystemColumn hideMultiselectColumn noHeaderRow
            hideGridMenu rowHeight="28" :activeRows="false">
            <OColumn colId="o365_ColumnName" field="Name" :headerName="$t('Name')" width="200" flexWidth="80" class="border-none" :cellTitle="row => row.Name" disableMenu/>
            <OColumn colId="o365_Aggregate" field="Aggregate" :headerName="$t('Aggregate')" flexWidth="20" width="200" class="border-none" :cellTitle="row => row.Aggregate" disableMenu
                :editable="row => !row.isDeveloperSet">       
                <template #editor="{row}">
                    <OSelect v-model="row.Aggregate" clearable>
                        <option v-for="opt in row.aggregateOptions" :value="opt">
                            {{opt}}
                        </option>
                    </OSelect>
                </template>
            </OColumn>
        </ODataGrid>
    </template>
    <b v-else class="text-danger">
        {{$t(`Warning: 'o365.vue.components.DataGrid.GridMenu.SummaryData.vue' cannnot be used outside ODataGrid`)}}
    </b>
</template>

<script setup lang="ts">
import type DataGridControl from 'o365-datagrid';
import type { Ref } from 'vue';
import { InjectionKeys, $t } from 'o365-utils';
import { inject, computed } from 'vue';
import { OSelect } from 'o365-ui-components';

const props = defineProps<{
    hideHeader?: boolean
}>();

const columns = computed(() => {
    const result = [];
    const addedColumns = new Set();
    dataGridControl.value?.dataColumns.columns.forEach(col => {
        if (!col.unbound && !addedColumns.has(col.colId)) {
            const aggregateOptions = ['COUNT', 'MAX', 'MIN'];
            if (col.type === 'number') {
                aggregateOptions.push('SUM', 'AVG')                
            }
            if (col.summaryAggregate && typeof col.summaryAggregate !== 'string') {
                result.push({
                    colId: col.colId,
                    Name: col.headerName,
                    Aggregate: $t('Developer set'),
                    isDeveloperSet: true
                });
            } else {
                result.push({
                    colId: col.colId,
                    Name: col.headerName,
                    aggregateOptions: aggregateOptions,
                    get Aggregate() { return col.summaryAggregate },
                    set Aggregate(pValue) {
                        col.summaryAggregate = pValue;
                        if (dataGridControl.value.updateSummaryAggregates) {
                            dataGridControl.value.updateSummaryAggregates();
                        }
                    },
                });
            }
        }
    })
    return result;
});

const dataGridControl: Ref<DataGridControl> = inject(InjectionKeys.dataGridControlKey, null);
</script>